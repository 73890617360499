<template>
  <div>
    <h3>Add Property Details Options</h3>
    <loading v-if="loading" />
    <div class="row mt-4" v-if="! loading">
      <div class="col-md-7">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  required
                  v-model="detail.key"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>Type</label>
                <select v-model="detail.type" class="form-control" required>
                  <option value="text">Text</option>
                  <option value="textarea">Textarea</option>
                  <option value="select">Select</option>
                </select>
              </div>
              <div class="form-group" v-if="detail.type == 'select'">
                <label>Options</label>
                <div class="input-group mb-3" v-for="(option, i) in detail.options" :key="`option-${i}`">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="`Option ${i+1}`"
                    v-model="detail.options[i]"
                    required
                  />
                  <a href="#" class="input-group-text" @click.prevent="detail.options.splice(i, 1)"
                    ><i class="lni lni-close"></i
                  ></a>
                </div>
                <p class="text-end"><a href="#" @click.prevent="detail.options.push('')"><i class="lni lni-plus me-2"></i> Add Option</a></p>
              </div>
              <div class="form-group">
                <button class="btn btn-theme">
                  <i class="lni lni-save me-2"></i>Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      detail: { options: [''] },
      loading: false
    };
  },

  methods: {
    fetch() {
      this.axios.get(`/api/v1/admin/details/${this.$route.params.id}`).then(response => {
        this.detail = response.data.detail
      })
    },

    submit() {
      this.loading = true
      this.axios.put(`/api/v1/admin/details/${this.$route.params.id}`, this.detail).then(() => {
        this.loading = false
        this.$router.push({ name: 'admin.details.index' })
        this.$swal({
          title: "Updated",
          text: "The item has been updated",
          type: 'success'
        })
      })
    }
  }
};
</script>
